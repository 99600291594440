import { Button, Tabs } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Drawer, TabsComponent, Text, theme } from 'rx-shared-lib';
import styled from 'styled-components';
import { fetchMeasures, fetchPatientDetails } from '../../../actions';
import { getPatientCallQueueStatus } from '../../../API/Monolith/patientAPI';
import { getFormattedDate, getReassignButtonText } from '../../../API/util';
import { useAppDispatch } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { AlertIcon } from '../../../component/icons/AlertIcon';
import { LeftArrayIcon } from '../../../component/icons/LeftArrowIcon';
import { RightArrayIcon } from '../../../component/icons/RightArrowIcon';
import { CURRENT } from '../../../constants';
import {
  setAllMeasureData,
  setAssigneeButtonText,
  setAssigneeSelector,
  setSelectedPatientCardTab,
  setSelectedPatientMeasureID,
} from '../../../slice/PatientSlice';
import {
  deselectAllAssignees,
  onNextPatientselection,
  onPaginationChanged,
  onPatientClosed,
  onPatientSelected,
  reloadGrid,
  searchPatient,
  setAnswerLoader,
  setOldAssignee,
} from '../dashboardSlice';
import { SelectedPatient } from '../Types';
import PatientCard from './PatientCard';
import PatientCardFooter from './PatientCardFooter';

export const DETAIL_BTN = 'DETAIL_BTN';
export const ADHER_BTN = 'ADHER_BTN';
export const NOTE_BTN = 'NOTE_BTN';
export const PATIENT_CARD = 'PATIENT_CARD';
export const PREVIOUS_ICON = 'PREVIOUS_ICON';
export const NEXT_ICON = 'NEXT_ICON';

const TabComponent = styled(TabsComponent)`
  .ant-tabs-content {
    max-height: 100%;
    overflow: auto;
  }
  .ant-tabs-tab {
    width: 196px;
    height: 30px;
  }
`;
const StyledArrowButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
`;
const StyledHeaderDetails = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`;
const TabPane = styled(Tabs.TabPane)``;
const TabLabel = styled(Text)`
  padding: 0 ${theme.spacingInPx(8)};
`;
const ArrowButton = styled(Button)`
  height: 48px;
  width: 48px;
  background: white;
  :disabled {
    visibility: hidden;
  }
  :hover {
    background: ${theme.stellusPalette.grey['10']};
  }
  .ant-click-animating-node {
    display: none;
  }
  padding-top: 0px;
  padding-bottom: 0px;
`;

const NoData = () => (
  <div
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      color: '#9F5710',
      marginLeft: '0.25rem',
    }}
  >
    <AlertIcon style={{ color: '#9F5710' }} size="14" />
    <span style={{ margin: '0.15rem 0 0 0.25rem', fontSize: '0.75rem' }}>No data</span>
  </div>
);

const CallQueueStatus = () => {
  const { selectedPatient } = useSelector((state: RootState) => state.dashboard);
  const { patientId } = (selectedPatient ?? {}) as SelectedPatient;
  const [callQueue, setCallQueue] = useState<any>();

  useEffect(() => {
    if (patientId) {
      getPatientCallQueueStatus(patientId)
        .then(setCallQueue)
        .catch(() => setCallQueue(null));
    }
  }, [patientId]);

  if (!callQueue) return null;

  return (
    <div
      style={{
        marginTop: '48px',
        backgroundColor: '#EDF9FC',
        padding: '14px 48px',
        display: 'flex',
        gap: '0.25rem',
        alignItems: 'baseline',
      }}
    >
      <AlertIcon style={{ color: '#177491' }} size="14" />
      <div style={{ display: 'flex', flexDirection: 'column', color: 'black' }}>
        <span style={{ fontWeight: 'bold' }}>
          Patient in automated call queue (call {callQueue.attempt - 1} of {callQueue.total_attempt}
          )
        </span>
        <div>
          <span>{_.capitalize(callQueue.json_data.measure_name)} </span>
          follow-up date: {getFormattedDate(callQueue.json_data.reminder_end_date)}
        </div>
      </div>
    </div>
  );
};

const PatientCardDrawer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedPatient, searchPatientClick, patient, offset, pageSize, isClicked } = useSelector(
    (state: RootState) => {
      return state.dashboard;
    },
  );

  const { selectedPatientDetails } = useSelector((state: RootState) => state.patient);

  const { patient: patientDetail } = selectedPatientDetails ?? {};

  const [disableClick, setDisableClick] = useState(false);
  const [disableNextClick, setDisableNextClick] = useState(false);

  const { selectedPatientCardTab } = useSelector((state: RootState) => state.patient);

  const tabChangeHandler = (key: string) => {
    dispatch(setSelectedPatientCardTab(key));
  };
  const fetchMeasuresAndMedication = async () => {
    dispatch(fetchMeasures());
  };
  const ensureAllMeasuresAreEmpty = () =>
    new Promise((resolve, reject) => {
      dispatch(setAllMeasureData({}));
      resolve(true);
    });
  useEffect(() => {
    ensureAllMeasuresAreEmpty().then(async () => {
      await dispatch(fetchPatientDetails());
      await fetchMeasuresAndMedication();
      dispatch(setAssigneeButtonText(getReassignButtonText(selectedPatient)));
    });
    dispatch(setOldAssignee(selectedPatient?.assignedUserId));
  }, [selectedPatient]);

  const disablePrevousButton = () => {
    if (searchPatientClick) return true;
    const index = patient?.result?.findIndex(
      (x: any) => x?.patientId === selectedPatient?.patientId,
    );
    if (disableClick || (index === 0 && offset === 0)) {
      return true;
    }
    return false;
  };

  const disableNextButton = () => {
    if (searchPatientClick) return true;
    const index = patient?.result?.findIndex(
      (x: any) => x?.patientId === selectedPatient?.patientId,
    );

    if (disableNextClick || offset + index === patient?.metadata?.total - 1) {
      return true;
    }
    return false;
  };
  const handlePreviousClick = async () => {
    dispatch(setAllMeasureData({}));
    dispatch(setAnswerLoader(false));
    dispatch(deselectAllAssignees());
    dispatch(setAssigneeSelector(false));
    const index = patient?.result?.findIndex(
      (x: any) => x.patientId === selectedPatient?.patientId,
    );
    if (!patient.result[index - 1]) {
      dispatch(
        onPaginationChanged({
          offset: (offset ?? 0) - (pageSize ?? 10),
          limit: pageSize,
          highlightRow: -1,
        }),
      );
      setDisableClick(true);
      setTimeout(() => {
        setDisableClick(false);
      }, 2000);
    } else {
      dispatch(onNextPatientselection(index - 1));
    }
  };

  const handleNextClick = async () => {
    dispatch(setAllMeasureData({}));
    dispatch(setAnswerLoader(false));
    dispatch(deselectAllAssignees());
    dispatch(setAssigneeSelector(false));
    const index = patient?.result?.findIndex(
      (x: any) => x.patientId === selectedPatient?.patientId,
    );

    if (!patient.result[index + 1]) {
      dispatch(
        onPaginationChanged({
          offset: (offset ?? 0) + (pageSize ?? 10),
          limit: pageSize,
          highlightRow: 0,
        }),
      );
      setDisableNextClick(true);
      setTimeout(() => {
        setDisableNextClick(false);
      }, 2000);
    } else {
      dispatch(onNextPatientselection(index + 1));
    }
  };
  const onPatientCardClose = () => {
    dispatch(setAllMeasureData({}));
    dispatch(onPatientSelected(null));
    dispatch(setSelectedPatientMeasureID(null));
    dispatch(onPatientClosed());
    dispatch(setAnswerLoader(false));
    dispatch(setAssigneeSelector(false));
    dispatch(deselectAllAssignees());
    dispatch(setAssigneeSelector(false));
    dispatch(setSelectedPatientCardTab('1'));

    if (searchPatientClick === true) {
      dispatch(searchPatient(selectedPatient));
    } else {
      dispatch(reloadGrid(CURRENT));
    }
  };

  const getPayer = () =>
    `${patientDetail?.payer ?? ''} ${patientDetail?.dataSourceOrganizationName ?? ''}`.trim();

  return (
    <Drawer
      bodyStyle={{ padding: '24px 48px 48px 48px' }}
      placement={'right'}
      headerStyle={{
        borderBottom: `1px solid ${theme.stellusPalette.grey['30']}`,
      }}
      title={
        <>
          <CallQueueStatus />
          <div style={{ paddingTop: '48px' }}>
            <StyledArrowButtonContainer
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '24px',
              }}
            >
              <ArrowButton
                shape="circle"
                type="default"
                data-testid={PREVIOUS_ICON}
                icon={<LeftArrayIcon />}
                // icon={<ChevronIcon chevronTyp//e='Left' />}
                onClick={handlePreviousClick}
                disabled={disablePrevousButton()}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '0 24px',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                <Text variant="SUBHEADING" shade="bold">
                  {selectedPatient?.firstName?.toLowerCase()}{' '}
                  {selectedPatient?.lastName?.toLowerCase()}
                </Text>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <StyledHeaderDetails>
                    <div>
                      <Text variant="P3">
                        {selectedPatient?.dob
                          ? moment(selectedPatient?.dob).format('MM/DD/YYYY')
                          : ''}
                      </Text>
                    </div>
                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                      <Text variant="P3">
                        {moment().year() - moment(selectedPatient?.dob).year() || <NoData />}
                      </Text>
                    </div>
                    <div>
                      <Text variant="P3">{patientDetail?.gender || <NoData />}</Text>
                    </div>
                  </StyledHeaderDetails>
                  <div
                    style={{
                      textAlign: 'left',
                      width: '200px',
                    }}
                  >
                    <Text variant="P3">
                      {`${t('patientCard:payer-label')}: `}
                      {getPayer() || <NoData />}
                    </Text>
                  </div>
                </div>
              </div>
              <ArrowButton
                shape="circle"
                type="default"
                data-testid={NEXT_ICON}
                icon={<RightArrayIcon />}
                onClick={handleNextClick}
                disabled={disableNextButton()}
              />
            </StyledArrowButtonContainer>
            <TabComponent
              centered
              type="card"
              size="small"
              activeKey={selectedPatientCardTab}
              onChange={tabChangeHandler}
            >
              <TabPane
                tab={
                  <TabLabel variant="P2" shade="bold" color="inherit">
                    {t('patientCard:measures')}
                  </TabLabel>
                }
                key="1"
              ></TabPane>

              <Tabs.TabPane
                tab={
                  <TabLabel variant="P2" shade="bold" color="inherit">
                    {t('common:notes')}
                  </TabLabel>
                }
                key="2"
              ></Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <TabLabel variant="P2" shade="bold" color="inherit">
                    {t('common:details')}
                  </TabLabel>
                }
                key="3"
              ></Tabs.TabPane>
            </TabComponent>
          </div>
        </>
      }
      onClose={onPatientCardClose}
      maskClosable={false}
      footer={<PatientCardFooter onPatientCardClose={onPatientCardClose} />}
      visible={selectedPatient !== null && isClicked === true}
      mask={false}
      width={720}
    >
      <PatientCard />
    </Drawer>
  );
};

export default PatientCardDrawer;
