import { Popover } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'rx-shared-lib';
import styled from 'styled-components';
import {
  cancelRefreshFiveNine,
  createRefreshFiveNine,
  sendFiveNineNotification,
} from '../../actions';
import { fetchPatientBasicInfo, updatePatientToCallQueue } from '../../API/Monolith/patientAPI';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import config from '../../config';
import {
  onPatientClicked,
  onSearchModalOpen,
  onSearchPatientClick,
  onSelectedSearchPatient,
  searchPatient,
  setSearchPatientId,
  setShowFiveNinePopover,
} from '../../features/dashboard/dashboardSlice';
import { PhoneIcon } from '../icons/PhoneIcon';
import { AlertIcon } from '../icons/AlertIcon';

const ButtonFiveNine = styled(Button)`
  background: #2b6678 !important;
`;

const PopoverFiveNine = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0px !important;
  }
`;

let interval: NodeJS.Timeout | undefined;

export const FiveNinePopover = () => {
  const dispatch = useAppDispatch();
  const [patientId, setPatientId] = useState<number>();
  const [showPatientInfo, setShowPatientInfo] = useState(false);

  const closePatientInfo = () => setShowPatientInfo(false);

  const { showFiveNinePopover } = useSelector((state: RootState) => {
    return state.dashboard;
  });

  const toggleFiveNinePopover = () => dispatch(setShowFiveNinePopover(!showFiveNinePopover));

  const updateCallToAnswered = async (
    campaign_name: string,
    patient_campaign_id: number,
    patient_id: number,
  ) => {
    try {
      const payload = {
        patient_id: patient_id,
        user_id: 0,
        patient_campaign_id: patient_campaign_id,
        campaign_name: campaign_name,
        operation_type: 'UPDATE_CALL_CAMPAIGN',
        operation_result: 'ANSWERED',
        channel: 'five9',
      };
      console.log('five9search', payload);

      await updatePatientToCallQueue(payload);

      sessionStorage.removeItem('five9_search');
    } catch (error) {
      console.error('Error updating patient call reminder', error);
    }
  };

  const handleAnsweredCall = (payload: any) => {
    const { campaign_name, patient_campaign_id, patient_id } = payload;

    setPatientId(patient_id);
    setShowPatientInfo(true);

    updateCallToAnswered(campaign_name, patient_campaign_id, patient_id);
  };

  const url = new URL(config.REACT_APP_FIVE9_BASE_URL);

  url.searchParams.append('f9verticalthreshold', '300px');
  url.searchParams.append('f9crmapi', 'true');

  useEffect(() => {
    const interactionApi = window.Five9.CrmSdk.interactionApi();
    const registerApi = window.Five9.CrmSdk.crmApi();

    registerApi.registerApi({
      getAdtConfig: function () {
        const config = {
          myCallsTodayEnabled: false,
          myChatsTodayEnabled: false,
          myEmailsTodayEnabled: false,
          showContactInfo: false,
        };

        return Promise.resolve(config);
      },
      saveLog: function () {},
      screenPop: function () {},
      enableClickToDial: function () {},
      disableClickToDial: function () {},
      search: (payload: any) => {
        const patient_id =
          payload.interactionSearchData?.crmList.find(
            (x: { name: string }) => x.name === 'patient_id',
          ).value ?? 0;

        const patient_campaign_id =
          payload.interactionSearchData?.crmList.find(
            (x: { name: string }) => x.name === 'patient_campaign_id',
          ).value ?? 0;

        const campaign_name: string = payload.interactionData?.campaignName;

        if (campaign_name?.startsWith('refill_reminder') && patient_id) {
          const five9SearchPayload = { campaign_name, patient_id, patient_campaign_id };

          sessionStorage.setItem('five9_search', JSON.stringify(five9SearchPayload));

          handleAnsweredCall(five9SearchPayload);
        }
      },
    });

    interactionApi.subscribe({
      callStarted: (payload: any) => {
        if (!showFiveNinePopover) {
          sendFiveNineNotification(payload);
          dispatch(setShowFiveNinePopover(true));
        }

        interval = createRefreshFiveNine();

        const five9Search = JSON.parse(sessionStorage.getItem('five9_search') ?? '{}');
        const { campaign_name, patient_id } = five9Search;

        if (campaign_name?.startsWith('refill_reminder') && patient_id) {
          handleAnsweredCall(five9Search);
        }
      },
      callEnded: () => {
        sessionStorage.removeItem('five9_search');
        setPatientId(undefined);
        cancelRefreshFiveNine(interval);
      },
    });

    return () => cancelRefreshFiveNine(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <PopoverFiveNine
      trigger="click"
      placement="bottom"
      visible={showFiveNinePopover}
      content={
        <>
          {showPatientInfo && (
            <PatientBasicInfo patientId={patientId} closePopover={closePatientInfo} />
          )}
          <iframe
            id="iframe-api-v2-panel"
            title="FiveNine Integration"
            width={299}
            height={600}
            src={url.toString()}
          />
        </>
      }
    >
      <ButtonFiveNine type="primary" onClick={toggleFiveNinePopover} icon={<PhoneIcon />}>
        {showFiveNinePopover ? 'Close' : 'Open'}
      </ButtonFiveNine>
    </PopoverFiveNine>
  );
};

const NoData = () => (
  <div
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      color: '#9F5710',
      marginLeft: '0.25rem',
    }}
  >
    <AlertIcon style={{ color: '#9F5710' }} size="14" />
    <span style={{ margin: '0.15rem 0 0 0.25rem', fontSize: '0.75rem' }}>No data</span>
  </div>
);

const PatientBasicInfo = ({
  patientId,
  closePopover,
}: {
  patientId?: number;
  closePopover: () => void;
}) => {
  const [userBasicInfo, setUserBasicInfo] = useState<any>();
  const dispatch = useDispatch();

  const getPatientBasicInfo = useCallback(async (id: number) => {
    try {
      const result = await fetchPatientBasicInfo(id);
      setUserBasicInfo(result);
    } catch (error) {
      console.error('Error fetching patient basic info', error);
    }
  }, []);

  const showPatientDrawer = async () => {
    dispatch(onSearchPatientClick(true));
    dispatch(setSearchPatientId(patientId));
    dispatch(onPatientClicked());
    dispatch(onSearchModalOpen(false));
    await dispatch(searchPatient({ id: patientId }));
    dispatch(onSelectedSearchPatient(0));
    closePopover();
  };

  useEffect(() => {
    const id = Number(patientId) ?? 0;

    if (!id) {
      setUserBasicInfo(undefined);
    } else if (id > 0) getPatientBasicInfo(id);
  }, [getPatientBasicInfo, patientId]);

  if (!userBasicInfo) return <></>;

  return (
    <div>
      <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <label style={{ color: '#757575', fontWeight: 'bold' }}>Inbound call</label>
        <Button style={{ padding: '4px 8px' }} onClick={showPatientDrawer}>
          Open patient drawer
        </Button>
      </section>
      <section>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '0.5rem',
          }}
        >
          <label>
            {userBasicInfo.firstName} {userBasicInfo.lastName}
          </label>
          <label>
            DOB <span>{userBasicInfo.birthDate}</span>
          </label>
        </div>
        <div
          style={{
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '0.5rem',
          }}
        >
          {userBasicInfo?.provider?.providerName ? (
            <label>{userBasicInfo?.provider.providerName}</label>
          ) : (
            <NoData />
          )}
          {userBasicInfo?.patientData.CLINIC_NAME ? (
            <label>{userBasicInfo?.patientData.CLINIC_NAME}</label>
          ) : (
            <NoData />
          )}
        </div>
      </section>
    </div>
  );
};
