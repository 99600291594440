import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardRow, CardRowContainer, InfoIcon, Radio, Text } from 'rx-shared-lib';
import { RadioGroup } from 'rx-shared-lib/dist/components/Radio/Radio';
import styled, { css } from 'styled-components';
import { getReassignButtonText } from '../../../../API/util';
import {
  fetchMeasures,
  fetchStepAnswer,
  sendScheduleFollowUpDateWithAnswer,
} from '../../../../actions';
import { RootState } from '../../../../app/store';
import {
  setAllMeasureData,
  setAssigneeButtonText,
  setAssigneeSelector,
} from '../../../../slice/PatientSlice';
import { Tracker } from '../../../../tracker';
import notificationBanner from '../../../../utility/notification';
import {
  CONTACT_PRESCRIBER_STEP_NAME,
  SCHEDULE_FOLLOWUP_STEP_ID,
} from '../../../../utility/rules.constants';
import {
  deselectAllAssignees,
  getPatientMeasureInfo,
  getStringReplaced,
} from '../../dashboardSlice';
import StepTable from '../StepTable';
import { CheckBoxGroupComponent } from './CheckboxComponent';
import StepHeader from './StepHeader';
import { getSuggestedFollowUpDate } from './getSuggestedFollowUpDate';
import CallQueueAlert from './CallQueueAlert';

export const STEP_SAVE_BUTTON = 'STEP_SAVE_BUTTON';
export const STEP_TABLE = 'STEP_TABLE';
export const STEP_QUESTION = 'STEP_QUESTION';
export const STEP_QUESTION_CONTAINER = 'STEP_QUESTION_CONTAINER';
export const STEP_ANSWER_MULTISELECT = 'STEP_ANSWER_MULTISELECT';
export const STEP_ANSWER_NOT_MULTISELECT = 'STEP_ANSWER_NOT_MULTISELECT';
export const STEP_HEADER = 'STEP_HEADER';
export const STEP_RADIO = 'STEP_RADIO';
export const STEP_DETAIL = 'STEP_DETAIL';
export const COLLAPSE = 'COLLAPSE';
export const PANEL = 'PANEL';
export const RADIO_BTN = 'RADIO_BTN';
export const SCHEDULETASK = 'SCHEDULETASK';
export const SCHEDULETASK_RADIO = 'SCHEDULETASK_RADIO';
export const SCHEDULETASK_DATE = 'SCHEDULETASK_DATE';
export const CHECKBOX = 'CHECKBOX';
const YES_FOLLOW_SCHEDULE_UP_ANSWER_ID = '625daca1b91534991007e711';
const NO_FOLLOW_SCHEDULE_UP_ANSWER_ID = '625daca1b91534991007e712';
const SHEDULE_FOLLOW_UP_QUESTION_ID = '625daca1b91534991007e710';
const SHEDULE_FOLLOW_UP_STEP_ID = '625daca1b91534991007e700';

interface NextStepComponentProps {
  measureId: any;
  stepData?: any;
  answerData?: any;
  taskId?: string | number;
}

const StyledContent = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: ${theme.spacingInPx(10)};
  `}
`;
const StyledButton = styled(Button)`
  ${({ theme }) => css`
    width: 170px;
  `}
`;
const StyledCardRow = styled(CardRow)`
  ${({ theme }) => css`
    .ant-collapse-arrow {
      right: -${theme.spacingInPx(1)} !important;
    }
    .ant-collapse-header {
      padding: 0px !important;
    }

    .ant-collapse-content-box {
      padding: 0px !important;
    }
  `}
`;
const StyledRadio = styled(Radio)`
  ${({ theme }) => css`
    max-height: 200px;
    line-height: 40px;
  `}
`;
const StyledQuestion = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacingInPx(3)} !important;
    margin-top: ${theme.spacingInPx(4)};
  `}
`;
const StyledQuestionContainer = styled('div')`
  ${({ theme }) => css`
    width: 100%;
    padding-left: ${theme.spacingInPx(4)};
  `}
`;
const NextStepComponent = ({ measureId, stepData, answerData, taskId }: NextStepComponentProps) => {
  const [isSaving, setIsSaving] = useState(false);

  const { t } = useTranslation();

  const { selectedPatientMedDetails, allMeasureData } = useSelector((state: RootState) => {
    return state.patient;
  });
  const dashboardState = useSelector((state: RootState) => {
    return state.dashboard;
  });
  const { selectedPatient, currentUser } = useSelector((state: RootState) => {
    return state.dashboard;
  });

  const dispatch = useDispatch();
  const latestQuestion = allMeasureData[measureId]?.answerData?.questionWithAnswers;
  const latestQuestionId = latestQuestion
    ? Object.keys(latestQuestion)[Object.keys(latestQuestion).length - 1]
    : '';

  const role: string = (currentUser?.role || '').toLowerCase();
  const assigneeId = allMeasureData[measureId]?.assigneeId?.toString();
  const currentUserId = currentUser?.userId?.toString();
  const stepName = stepData?.name || '';
  const STEPS_ALLOWED_ONLY_FOR_PHARMACIST = ['Triage to Pharmacist'];

  const userDisabledFunc = (questionId: any, elementId?: any) => {
    if (['admin'].includes(role)) {
      return true;
    } else if (assigneeId === currentUserId) {
      if (
        ['technician', 'intern'].includes(role) &&
        STEPS_ALLOWED_ONLY_FOR_PHARMACIST.includes(stepName)
      ) {
        return true;
      } else {
        if ('schedule-data-picker' === elementId) {
          return !isEmpty(
            allMeasureData[measureId].stepAnswers?.questionWithAnswers[questionId]?.actionDate ??
              [],
          );
        } else {
          return !isEmpty(
            allMeasureData[measureId].stepAnswers?.questionWithAnswers[questionId] ?? [],
          );
        }
      }
    } else {
      return true;
    }
  };

  const enableDisableFollowupQuestions = (questionId: any) => {
    // console.log('buttonDisableFunc4')
    if (
      allMeasureData[measureId] &&
      allMeasureData[measureId]?.answerData?.questionWithAnswers &&
      allMeasureData[measureId]?.answerData?.questionWithAnswers[questionId][0]?.id !==
        YES_FOLLOW_SCHEDULE_UP_ANSWER_ID
    ) {
      // console.log('buttonDisableFunc5')
      return false;
    } else {
      // console.log('buttonDisableFunc6')
      return allMeasureData &&
        allMeasureData[measureId] &&
        allMeasureData[measureId]?.answerData?.questionWithAnswers
        ? allMeasureData[measureId]?.answerData?.scheduleFollowUp === undefined
        : true;
    }
  };

  const disableSelectedQuestions = (questionId: any) => {
    // console.log('buttonDisableFunc2')
    if (
      isEmpty(
        allMeasureData[measureId] && allMeasureData[measureId]?.answerData?.questionWithAnswers
          ? allMeasureData[measureId]?.answerData?.questionWithAnswers[questionId]
          : [],
      )
    ) {
      // console.log('buttonDisableFunc3')
      return true;
    } else {
      return enableDisableFollowupQuestions(questionId);
    }
  };

  const buttonDisableFunc = (questionId: any) => {
    if (['admin'].includes(role)) {
      return true;
    } else if (assigneeId === currentUserId) {
      if (
        ['technician', 'intern'].includes(role) &&
        STEPS_ALLOWED_ONLY_FOR_PHARMACIST.includes(stepName)
      ) {
        return true;
      } else {
        if (latestQuestionId !== SHEDULE_FOLLOW_UP_QUESTION_ID) {
          // console.log('buttonDisableFunc1')
          return isEmpty(
            allMeasureData[measureId] && allMeasureData[measureId]?.answerData?.questionWithAnswers
              ? allMeasureData[measureId]?.answerData?.questionWithAnswers[questionId]
              : [],
          );
        } else {
          return disableSelectedQuestions(questionId);
        }
      }
    } else {
      return true;
    }
  };

  const saveAnswerAndFetchMeasure = async () => {
    await dispatch(
      fetchStepAnswer(
        measureId,
        taskId,
        stepData._id,
        latestQuestionId,
        latestQuestion[latestQuestionId],
        selectedPatient?.patientId,
        stepData.uId,
      ),
    );
    dispatch(fetchMeasures());
  };
  const handleRadioChange = (event: any, child: any, option: any) => {
    dispatch(
      setAllMeasureData({
        ...allMeasureData,
        [measureId]: {
          ...allMeasureData[measureId],
          answerData: {
            ...allMeasureData[measureId]?.answerData,
            questionWithAnswers: {
              ...allMeasureData[measureId]?.answerData.questionWithAnswers,
              [child?._id]: [{ id: event.target.value, name: option?.name }],
            },
            scheduleFollowUp: undefined,
          },
        },
      }),
    );
  };
  const onSave = () => {
    setIsSaving(true);
    dispatch(setAssigneeSelector(false));
    dispatch(deselectAllAssignees());
    dispatch(setAssigneeButtonText(getReassignButtonText(selectedPatient)));
    if (stepData._id === SCHEDULE_FOLLOWUP_STEP_ID) {
      if (
        allMeasureData[measureId] &&
        allMeasureData[measureId]?.answerData?.questionWithAnswers &&
        allMeasureData[measureId]?.answerData?.questionWithAnswers[latestQuestionId][0]?.id ===
          YES_FOLLOW_SCHEDULE_UP_ANSWER_ID
      ) {
        let questionId;
        let answers;
        if (isEmpty(allMeasureData[measureId].stepAnswers?.questionWithAnswers[latestQuestionId])) {
          // console.log('save both');
          questionId = latestQuestionId;
          answers = latestQuestion[latestQuestionId];
        } else {
          // console.log('save only date');
        }
        dispatch(
          sendScheduleFollowUpDateWithAnswer({
            measureId: measureId,
            taskId: taskId,
            stepId: stepData?._id,
            questionId: questionId,
            answers: answers,
            patientId: selectedPatient?.patientId,
            uId: stepData.uId,
            scheduleFollowUp: allMeasureData[measureId]?.answerData?.scheduleFollowUp,
          }),
        )
          .catch(saveMeasureGapFailed)
          .finally(() => setIsSaving(false));
      } else {
        saveAnswerAndFetchMeasure()
          .catch(saveMeasureGapFailed)
          .finally(() => setIsSaving(false));
      }
    } else {
      dispatch(
        fetchStepAnswer(
          measureId,
          taskId,
          stepData._id,
          latestQuestionId,
          latestQuestion[latestQuestionId],
          selectedPatient?.patientId,
          stepData.uId,
        ),
      )
        .catch(saveMeasureGapFailed)
        .finally(() => setIsSaving(false));
    }
  };

  const saveMeasureGapFailed = (error: any) => {
    const isTimeoutError =
      error.code === 'ECONNABORTED' || error.response?.data?.message?.includes('timeout');
    const errorTitle = isTimeoutError
      ? t('common:save-measuregap-timeout')
      : t('common:save-measuregap-failed');
    notificationBanner({
      type: 'error',
      message: (
        <Typography.Text>
          <b>{errorTitle}</b>
        </Typography.Text>
      ),
      description: (
        <Typography.Text>{t('common:please-contact-your-system-administrator')}</Typography.Text>
      ),
    });
  };

  const patientUserInfo = getPatientMeasureInfo(
    dashboardState || {},
    measureId,
    selectedPatientMedDetails,
  );

  const radioStyle = {
    maxHeight: '200px',
    lineHeight: '40px',
  };

  const [eventToggleStep, setEventToggleStep] = useState({
    stepIsOpen: false,
    stepEvent: Tracker.events.openCardRow,
  });
  const toggleStep = () => {
    setEventToggleStep(eventToggleStep => ({
      stepIsOpen: !eventToggleStep.stepIsOpen,
      stepEvent: !eventToggleStep.stepIsOpen
        ? Tracker.events.closeCardRow
        : Tracker.events.openCardRow,
    }));
  };

  const SuggestedFollowUpDate = ({ date }: { date: string }) => (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: '#EDF9FC',
        border: '1px solid #84D3EB',
        borderRadius: '8px',
        color: '#000',
        display: 'flex',
        gap: '12px',
        margin: '20px 0',
        padding: '8px 12px',
        width: '100%',
      }}
    >
      <InfoIcon style={{ color: '#177491' }} size="14" />
      <span>Suggested next follow-up: {date}</span>
    </div>
  );

  const suggestedFollowUpDate = useMemo(() => {
    const measureData = allMeasureData[measureId];
    if (!measureData) {
      return '';
    }
    const claimData = measureData.medications?.[0]?.medicationData?.CLAIM ?? {};
    return getSuggestedFollowUpDate(
      measureData.claimFound,
      stepData?._id,
      measureData.lastImpactDateUpd ?? '',
      measureData.nextExpectedFillDateUpd ?? '',
      claimData.DAYS_SUPPLY,
    );
  }, [allMeasureData, stepData?._id]);

  return (
    <>
      <CardRowContainer onChange={toggleStep}>
        {suggestedFollowUpDate && <SuggestedFollowUpDate date={suggestedFollowUpDate} />}
        <StyledCardRow
          key="1"
          header={
            <StepHeader
              measureId={measureId}
              stepData={stepData}
              latestQuestionId={latestQuestionId}
              taskId={taskId}
              allMeasureData={allMeasureData}
              selectedPatient={selectedPatient}
              userDisabledFunc={userDisabledFunc}
              data-testid={STEP_HEADER}
              stepEvent={eventToggleStep.stepEvent}
              suggestedFollowUpDate={suggestedFollowUpDate}
            />
          }
        >
          <StyledContent data-testid={STEP_DETAIL} key={measureId}>
            {eventToggleStep.stepIsOpen && (
              <CallQueueAlert measureData={allMeasureData[measureId]} />
            )}
            {(stepData?.name === 'Research' ||
              stepData?.name === CONTACT_PRESCRIBER_STEP_NAME ||
              stepData?.name === 'Contact Patient' ||
              stepData?.name === 'Call Pharmacy') && (
              <StepTable type={stepData?.name} measureId={measureId} data-testid={STEP_TABLE} />
            )}
            {stepData?.children.map((child: any) => {
              return (
                <StyledQuestionContainer data-testid={STEP_QUESTION_CONTAINER} key={child?._id}>
                  <div>
                    <StyledQuestion variant="P2" shade="bold" data-testid={STEP_QUESTION}>
                      {getStringReplaced(child.name, patientUserInfo)}
                    </StyledQuestion>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    {child.isMultiSelect === undefined ? (
                      <div data-testid={STEP_ANSWER_NOT_MULTISELECT}>
                        <RadioGroup value={answerData?.questionWithAnswers[child?._id][0]?.id}>
                          {child.children?.map((option: any, k: number) => {
                            return (
                              <div
                                data-tracker-event={Tracker.events.selectOption}
                                data-tracker-step={stepData?.name}
                                data-tracker-option={option.name.replace(/\[|\]/g, '')}
                              >
                                <StyledRadio
                                  data-testid={RADIO_BTN}
                                  disabled={userDisabledFunc(child?._id)}
                                  checked={true}
                                  key={option._id}
                                  onChange={event => handleRadioChange(event, child, option)}
                                  value={option?._id}
                                >
                                  <Text variant="P2" shade="regular">
                                    {getStringReplaced(option.name, patientUserInfo)}
                                  </Text>
                                </StyledRadio>
                              </div>
                            );
                          })}
                        </RadioGroup>
                      </div>
                    ) : (
                      <CheckBoxGroupComponent
                        key={`${stepData._id}-${child._id}`}
                        options={child.children || []}
                        answerData={answerData}
                        questionId={child?._id}
                        radioStyle={radioStyle}
                        userDisabled={userDisabledFunc(child._id)}
                        patientUserInfo={patientUserInfo}
                        measureId={measureId}
                        data-testid={STEP_ANSWER_MULTISELECT}
                        stepData={stepData}
                      />
                    )}
                  </div>
                </StyledQuestionContainer>
              );
            })}
            <StyledButton
              isDisabled={isSaving || buttonDisableFunc(latestQuestionId)}
              type="default"
              onClick={onSave}
              size="small"
              data-testid={STEP_SAVE_BUTTON}
              data-tracker-event={Tracker.events.saveStep}
              data-tracker-step={stepData?.name}
              data-tracker-measure={patientUserInfo.measureName}
            >
              {t('common:save')}
            </StyledButton>
          </StyledContent>
        </StyledCardRow>
      </CardRowContainer>
    </>
  );
};

export default NextStepComponent;
