import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Users {
  users: any;
  searchUsers?: any;
  selectedUser?: any;
  roles: any[];
  dataSources: any[];
  errorOnCreate?: any;
  closeUserModal?: boolean;
  offset?: number;
  limit?: number;
  highlightRow?: number;
  pageSize?: number;
  selectedSort?: any;
  editUser?: any;
  selectedUserId?: number;
  searchPageNumber?: number;
  searchLoader?: boolean;
  searchedResultTotal?: number;
  searchUserSelected?: boolean;
}

const initialState: Users = {
  users: { data: undefined, total: 0 },
  searchUsers: { data: undefined, total: 0 },
  roles: [],
  dataSources: [],
  offset: 0,
  limit: 10,
  pageSize: 10,
  searchedResultTotal: 0,
  searchPageNumber: 1,
  searchUserSelected: false,
};

export const UsersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<any>) => {
      state.roles = action.payload;
    },
    setDataSources: (state, action: PayloadAction<any>) => {
      state.dataSources = action.payload;
    },
    setErrorOnCreate: (state, action: PayloadAction<any>) => {
      state.errorOnCreate = action.payload;
    },
    setCloseUserModal: (state, action: PayloadAction<any>) => {
      state.closeUserModal = action.payload;
    },
    setUserList: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
    setSearchUserList: (state, action: PayloadAction<any>) => {
      state.searchUsers =
        action.payload.searchPageNumber == 1
          ? action.payload.data
          : [...state.searchUsers, ...action.payload.data];
      state.searchedResultTotal = action.payload.total;
    },
    onUserSelection: (state, action: PayloadAction<any>) => {
      state.editUser = action.payload;
    },
    onPaginationChanged: (state, action: PayloadAction<any>) => {
      state.offset = action.payload.offset;
      state.limit = action.payload.limit;
      state.highlightRow = action.payload.highlightRow;
    },
    onPageSizeChanged: (state, action: PayloadAction<any>) => {
      state.pageSize = action.payload;
      state.offset = 0;
      state.limit = action.payload;
    },
    setUsersSort: (state, action: PayloadAction<any>) => {
      state.selectedSort = action.payload;
    },
    setSearchLoader: (state, action: PayloadAction<any>) => {
      state.searchLoader = action.payload;
    },
    onSearchedPage: (state, action: PayloadAction<any>) => {
      state.searchPageNumber = action.payload;
    },
    onSearchUserSelected: (state, action: PayloadAction<any>) => {
      state.searchUserSelected = action.payload;
    },
    resetUsers: () => initialState,
  },
});

export const {
  setRoles,
  setDataSources,
  setErrorOnCreate,
  setCloseUserModal,
  setUserList,
  onPaginationChanged,
  onPageSizeChanged,
  setUsersSort,
  resetUsers,
  onUserSelection,
  setSearchUserList,
  setSearchLoader,
  onSearchedPage,
  onSearchUserSelected,
} = UsersSlice.actions;

export default UsersSlice.reducer;
