import { useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  ExclamationCircleFilled,
  CheckCircleFilled,
  CloseOutlined,
  MinusCircleFilled,
} from '@ant-design/icons';

import {
  getPatientCallQueue,
  addPatientToCallQueue,
  removePatientToCallQueue,
} from '../../../../API/Monolith/patientAPI';
import { getFormattedDate } from '../../../../API/util';

interface Reminder {
  uuid: string;
  call_status: 'PENDING' | 'READY' | 'DONE' | 'ERROR' | 'CANCEL';
  attempt: number;
  total_attempt: number;
  campaign_date: string;
  json_data: { patient_measure_id: number; reminder_end_date: string };
}

export default function CallQueueAlert({ measureData }: { measureData: any }) {
  const [hidden, setHidden] = useState(true);
  const [reminder, setReminder] = useState<Reminder>();

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const patientId = measureData?.patient?.patientId;
  const patientMeasureId = measureData?.measureInfo?.id;
  const isContactPatientStep = measureData?.stepData?.at(-1)?.name === 'Contact Patient';

  const onAddHandler = useCallback(() => {
    if (patientId && patientMeasureId) {
      setIsLoading(true);
      addPatientToCallQueue(patientId, patientMeasureId)
        .then(data => {
          setReminder(data);
          setSuccess('Patient added to call queue');
        })
        .catch(error => setError(getAddErrorMessage(error)))
        .finally(() => setIsLoading(false));
    }
  }, [patientId, patientMeasureId]);

  const onRemoveHandler = useCallback(() => {
    if (patientId && patientMeasureId && reminder?.uuid) {
      setIsLoading(true);
      removePatientToCallQueue(patientId, patientMeasureId, reminder.uuid)
        .then(() => {
          setReminder(undefined);
          setSuccess('Patient removed from list');
        })
        .catch(error => setError(getRemoveErrorMessage(error)))
        .finally(() => setIsLoading(false));
    }
  }, [patientId, patientMeasureId, reminder?.uuid]);

  const onDismissHandler = useCallback(() => {
    setError('');
    setSuccess('');
  }, []);

  useEffect(() => {
    if (patientId && patientMeasureId) {
      getPatientCallQueue(patientId, patientMeasureId)
        .then(data => {
          setHidden(false);
          setReminder(data);
        })
        .catch(() => {
          setHidden(true);
        });
    }
  }, [patientId, patientMeasureId]);

  if (hidden) {
    return null;
  }

  if (
    isContactPatientStep &&
    ['PENDING', 'READY'].includes(reminder?.call_status as string) &&
    reminder?.json_data?.patient_measure_id !== patientMeasureId
  ) {
    return (
      <Alert variant="disabled">
        <Message variant="disabled">Patient already in call queue for another measure</Message>
        <Button disabled>Add to call queue</Button>
      </Alert>
    );
  }

  if (
    reminder &&
    ['PENDING', 'READY'].includes(reminder?.call_status as string) &&
    reminder?.json_data?.patient_measure_id === patientMeasureId &&
    !(success || error)
  ) {
    return (
      <Alert variant="warning">
        <Message variant="warning">
          <ExclamationCircleFilled />
          <div style={{ display: 'flex', flexDirection: 'column', color: 'black' }}>
            <label style={{ fontWeight: 'bold' }}>
              Patient in automated call queue until{' '}
              {getFormattedDate(reminder.json_data.reminder_end_date)}
            </label>
            <label>
              Outbound call attempts: {reminder.attempt - 1} of {reminder.total_attempt}
            </label>
          </div>
        </Message>
        <Button onClick={onRemoveHandler} disabled={isLoading} error>
          <MinusCircleFilled />
          Remove from queue
        </Button>
      </Alert>
    );
  }

  if (success) {
    return (
      <Alert variant="success">
        <Message variant="success">
          <CheckCircleFilled />
          {success}
        </Message>
        <Close onClick={onDismissHandler}>
          <CloseOutlined />
        </Close>
      </Alert>
    );
  }

  if (error) {
    return (
      <Alert variant="warning">
        <Message variant="warning">
          <ExclamationCircleFilled />
          {error}
        </Message>
        <Dismiss onClick={onDismissHandler}>Dismiss</Dismiss>
      </Alert>
    );
  }

  if (isContactPatientStep) {
    return (
      <Alert>
        <Message>Add patient to automated call queue</Message>
        <Button onClick={onAddHandler} disabled={isLoading}>
          Add to call queue
        </Button>
      </Alert>
    );
  }

  return null;
}

function getAddErrorMessage(error: any) {
  if (error?.response?.data?.statusCode >= 500) {
    return 'Unable to add patient to call queue. Please try again later';
  }
  if (error?.response?.data?.message) {
    return error?.response.data.message;
  }
  return 'Unable to add patient to call queue';
}

function getRemoveErrorMessage(error: any) {
  if (error?.response?.data?.statusCode >= 500) {
    return 'Unable to remove patient from call queue. Please try again later';
  }
  if (error?.response?.data?.message) {
    return error?.response.data.message;
  }
  return 'Unable to remove patient from call queue';
}

type AlertType = 'success' | 'warning' | 'disabled' | 'default';

const alertVariant: Record<AlertType, string> = {
  success: 'background: #e0f7e5; border-color: #a2e7a7;',
  warning: 'background: #fff5eb; border-color: #ffd6ad;',
  disabled: 'background: #f5f5f5; border-color: #d9d9d9;',
  default: 'background: #f0faf9; border-color: #a5d1df;',
};

const Alert = styled('div')<{ variant?: AlertType }>`
  ${({ variant = 'default' }) => css`
    display: flex;
    gap: 8px;
    width: 100%;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 16px;
    ${alertVariant[variant]}
  `}
`;

const textVariant: Record<AlertType, string> = {
  success: 'color: #217f32;',
  warning: 'color: #9F5710;',
  disabled: 'color: rgba(0, 0, 0, 0.55);',
  default: 'color: #32768a;',
};

const Message = styled('span')<{ variant?: AlertType }>`
  ${({ variant = 'default' }) => css`
    ${textVariant[variant]}
    font-weight: ${variant === 'default' ? 'bold' : 'normal'};
    flex-grow: 1;
    display: flex;
    gap: 8px;
    align-items: center;
  `}
`;

const Button = styled('button')<{ error?: boolean; disabled?: boolean }>`
  ${({ error = false, disabled = false }) => css`
    height: 24px;
    background: ${disabled ? 'rgba(0, 0, 0, 0.04)' : '#ffffff'};
    appearance: none;
    padding: 0 8px;
    border-radius: 999px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    gap: 8px;
    align-items: center;
    border-color: ${error ? '#c52020' : '#d9d9d9'};
    color: ${error ? '#c52020' : disabled ? 'rgba(0, 0, 0, 0.55)' : 'rgba(0, 0, 0, 0.88)'};
    text-wrap: nowrap;
    cursor: ${disabled ? 'auto' : 'pointer'};
    pointer-events: ${disabled ? 'none' : 'auto'};
  `}
`;

const Dismiss = styled('button')`
  height: 24px;
  background: transparent;
  appearance: none;
  padding: 0 8px;
  border: none;
  color: rgba(0, 0, 0, 0.88);
  cursor: pointer;
`;

const Close = styled('button')`
  height: 24px;
  width: 24px;
  background: transparent;
  appearance: none;
  padding: 0 4px;
  border: none;
  color: rgba(0, 0, 0, 0.55);
  cursor: pointer;
`;
